


























































import { RouterNames } from "@/router/routernames";
import { appointment } from "@/shared/dtos/appointment";
import appointmentModule from "@/store/modules/appointment-module";
import AppointmentModule from "@/store/modules/appointment-module";
import { UtilsDate } from "@/utils/utils-date";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {}
})
export default class AsistenteEditCita extends Vue {
  public e1 = 1;
  public cita: appointment = new appointment();
  public created() {
    this.cita = AppointmentModule.appointments.find(
      x => x.UniqueID === Number.parseInt(this.$route.params.id_cita)
    )!;
    //@ts-ignore
    this.cita.fecha =
      this.cita.fecha.toString().split("-")[2] +
      "-" +
      this.cita.fecha.toString().split("-")[1] +
      "-" +
      this.cita.fecha.toString().split("-")[0];
  }
  public get stilo() {
    //this.cita.id_nutricionista
    let h = this.$vuetify.breakpoint.height - 220;
    return (
      "height:" +
      h +
      "px;overflow: auto;     align-items: center;justify-content: center;display: table;"
    );
  }
  public get nutricionistas() {
    return [
      { id: 1, nombre: "Eric", direccion: "Maestro chapí Alginet" },
      { id: 2, nombre: "Miriam", direccion: "Maestro chapí Alcudia" }
    ];
  }

  public Close() {
    this.$router.push({ name: RouterNames.Home });
  }

  public Save() {
    //citasModule.AddCita(this.cita);
    appointmentModule
      .modificarappointment(this.cita)
      .then(() => this.$router.push({ name: RouterNames.citas_menu }));
  }
}
